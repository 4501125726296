import * as React from 'react';
import { FC, useEffect, useState, SyntheticEvent } from 'react';
import { AuthApi } from '../../api/AuthApi';
import { AuthResult } from '../../api/types/Auth';
import { Events } from '../../constants/events';
import * as Constants from '../../constants/google';
import { assertUnreachable } from '../../generic/helpers/unreachable';

const MANUAL_LOG_OUT_KEY = 'manual-log-out';
const MANUAL_LOG_OUT_TRUE_VALUE = 'true';
const MANUAL_LOG_OUT_FALSE_VALUE = 'false';

interface UserPhotoProps {
	picture: string;
	name: string;
}

const NoUserIcon: FC = () => <i className="material-icons">{String.fromCharCode(59473)}</i>;

const UserPhoto: FC<UserPhotoProps> = (props: UserPhotoProps) => {

	const [hasErrored, setHasErrored] = useState(false);

	function onError(event: SyntheticEvent<HTMLImageElement>) {
		if (!hasErrored) {
			event.currentTarget.src = '/content/image/no-user.svg';
		}
		setHasErrored(true);
	}

	return <img src={props.picture} alt="" onError={onError} />;
}

const AuthenticatorButton: FC = () => {
	const [loggedIn, setLoggedIn] = useState(window.mtd && window.mtd.user && window.mtd.user.loggedIn);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);

	const [name, setName] = useState(window.mtd && window.mtd.user ? window.mtd.user.name : null);
	const [picture, setPicture] = useState(window.mtd && window.mtd.user ? window.mtd.user.picture : null);

	useEffect(() => {
		window.mtd.auth = {
			manualLogout: async function (): Promise<void> {
				google.accounts.id.disableAutoSelect();
				try {
					await AuthApi.logout();
					setLoggedIn(false);
				} catch (ex) {
					setError(true);
					console.debug('Error logging out', ex);
				}
				window.dispatchEvent(new CustomEvent(Events.UserLogoutEventName));
				sessionStorage.setItem(MANUAL_LOG_OUT_KEY, MANUAL_LOG_OUT_TRUE_VALUE);
			},
			manualLogin: loginButtonClicked
		};

		google.accounts.id.initialize({
			client_id: Constants.GoogleYoloClientId,
			callback: yoloCallback,
			auto_select: true,
			context: 'signin'
		});

		if (!(loggedIn || isManuallyLoggedOut())) {
			prompt(window.mtd && window.mtd.user && window.mtd.user.prompt);
		}
	}, [window.mtd.user]);

	function redirectToAccount(): void {
		if (document.location.href.indexOf('/account') === -1) {
			document.location.href = '/account/';
		}
	}

	async function yoloCallback(rsp: google.accounts.CredentialResponse): Promise<void> {
		setLoading(true);
		let result: AuthResult;
		try {
			result = await AuthApi.login(rsp);
		} catch (ex) {
			console.error('error signing in');
			setError(true);
		} finally {
			setLoading(false);
		}
		if (result.Authenticated) {
			setLoggedIn(true);
			setName(result.First);
			setPicture(result.Picture);
			window.dispatchEvent(new CustomEvent(Events.UserAuthEventName));
		} else {
			console.warn('Unable to log in user', result);
			setError(true);
		}
	}

	function isManuallyLoggedOut(): boolean {
		return sessionStorage.getItem(MANUAL_LOG_OUT_KEY) === MANUAL_LOG_OUT_TRUE_VALUE;
	}

	function prompt(redirectOnFail?: boolean): void {

		if (isManuallyLoggedOut()) {
			console.debug('skipping prompt because manually logged out');
			if (redirectOnFail) {
				redirectToAccount();
			}
			return;
		}

		function promptCallback(notification: google.accounts.Notification): void {
			const momentType = notification.getMomentType();
			switch (momentType) {
				case 'dismissed':
					const dismissedReason = notification.getDismissedReason();
					if (dismissedReason === 'credential_returned') {
						console.debug('Google one tap sign in dismissed event', dismissedReason);
						return; // SUCCESS
					}
					console.debug('Google one tap dismissed. Reason:', dismissedReason);
					break;
				case 'display':
					if (notification.isNotDisplayed()) {
						const notDisplayedReason = notification.getNotDisplayedReason();
						console.debug('Google one tap not displayed. Reason:', notDisplayedReason);
					} else {
						console.debug('Google one tap sign in display event in progress');
						return; // IN PROGRESS
					}
					break;
				case 'skipped':
					const skippedReason = notification.getSkippedReason();
					console.debug('Google one tap skipped. Reason:', skippedReason);;
					break;
				default:
					assertUnreachable(momentType);
			}

			// we will only reach here if we failed to sign in.
			if (redirectOnFail) {
				redirectToAccount();
			}
		}
		google.accounts.id.prompt(promptCallback);
	}

	function loginButtonClicked() {
		sessionStorage.setItem(MANUAL_LOG_OUT_KEY, MANUAL_LOG_OUT_FALSE_VALUE)
		setLoading(true);
		prompt(true);
	}

	if (error) {
		return (
			<a href="/account/" className="login-button login">
				<i className="material-icons" aria-hidden="true">{String.fromCharCode(57344)}</i><span>Login Error</span>
			</a>
		);
	}

	if (loading) {
		return (
			<div className="login-button login">
				<span>Logging you in...</span>
			</div>
		);
	}

	if (loggedIn) {
		return (
			<a className="login-button account" href="/account/">
				<UserPhoto name={name} picture={picture} />
				<span>Account</span>
			</a>
		);
	}

	return (
		<button className="login-button login" onClick={loginButtonClicked}>
			<NoUserIcon />
			<span>Log in</span>
		</button>
	);

};

export default AuthenticatorButton;
