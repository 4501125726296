export module GenericApi {

	const MergeDefaultGet: (options: RequestInit) => RequestInit = (options: RequestInit) => ({
		method: 'GET',
		credentials: 'same-origin',
		...options
	});

	export async function fireAndForget(url: string, options: RequestInit = {}): Promise<boolean> {
		const init: RequestInit = MergeDefaultGet(options);
		const request: Request = new Request(url, init);
		const response: Response = await fetch(request);
		if (response.status === 200) {
			return true;
		}
		return false;
	}

	export async function getRequest<T>(url: string, options: RequestInit = {}): Promise<T> {
		const init: RequestInit = MergeDefaultGet(options);

		return await genericRequest<T>(url, init);
	}

	export async function postRequest<TBody, TResult>(url: string, body: TBody, options: RequestInit = {}): Promise<TResult> {
		const init: RequestInit = {
			method: 'POST',
			credentials: 'same-origin',
			body: JSON.stringify(body),
			...options
		};

		return await genericRequest<TResult>(url, init);
	}

	export async function postFormData<TResult>(url: string, body: FormData, options: RequestInit = {}): Promise<TResult> {
		const init: RequestInit = {
			method: 'POST',
			credentials: 'same-origin',
			body,
			...options
		};

		return await genericRequest<TResult>(url, init);
	}

	async function genericRequest<T>(url: string, init: RequestInit): Promise<T> {
		const request: Request = new Request(url, init);
		const response: Response = await fetch(request);
		if (response.status === 200) {
			return await response.json() as T;
		}
		throw `Status ${response.status}: when fetching '${url}'`;
	}

}
