import * as React from 'react';
import * as reactDOM from 'react-dom';
import AuthenticatorButton from './loginButton/components/App';

(() => {
	function yolo(): void {
		const authenticatorButtonContainer: HTMLElement = document.getElementById('login-button-app');
		if (authenticatorButtonContainer) {
			const authenticatorButtonApp = <AuthenticatorButton />;
			reactDOM.render(authenticatorButtonApp, authenticatorButtonContainer);
		}
	}

	if (window.google && window.google.accounts && window.google.accounts.id) {
		// library is already loaded
		yolo();
	} else {
		// wait for library to load
		window.onGoogleLibraryLoad = yolo;
	}
})();

