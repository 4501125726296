import { GenericApi } from './GenericApi';
import { AuthResult } from './types/Auth';

export module AuthApi {
	const BASE_INFO_URL: string = '/umbraco/surface/auth';

	export async function login(loginResponse: google.accounts.CredentialResponse): Promise<AuthResult> {
		return await GenericApi.postRequest<google.accounts.CredentialResponse, AuthResult>(`${BASE_INFO_URL}/login/`, loginResponse);
	}

	export async function logout(): Promise<void> {
		await GenericApi.fireAndForget(`/logout/`);
	}
}
